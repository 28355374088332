<template>
  <div class="group-item" :class="active ? 'active' : ''">
    <div class="group-avatar">
      <head-image
        :size="46"
        radius="50%"
        :name="group.showGroupName"
        :url="group.headImage"
        :id="0"
      >
      </head-image>
    </div>
    <div class="group-name">
      <div>{{ group.showGroupName }}</div>
    </div>
  </div>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";

export default {
  name: "groupItem",
  components: {
    HeadImage,
  },
  data() {
    return {};
  },
  props: {
    group: {
      type: Object,
    },
    active: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" >
.group-item {
  display: flex;
  position: relative;
  align-items: center;
  background-color: white;
  white-space: nowrap;
  cursor: pointer;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 6px;

  &:hover {
    background-color: #f8faff;
  }

  &.active {
    background-color: #f4f9ff;
  }

  .group-avatar {
    width: 45px;
    height: 45px;
  }

  .group-name {
    padding-left: 10px;
    height: 100%;
    text-align: left;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    color: #333;
  }
}
</style>
